import React, { useEffect, useState, useRef } from "react";

import * as CONST from "../../CONSTANTS";
import { Input } from "reactstrap";
import BackButton from "./backButton";
import NextButton from "./nextButton";

export default function name({ fData, handleNextClick, handleBackClick }) {
  const QUERY = "What is your Name?";
  const NEXT_COMPONENT = CONST.CN_ADDRESS;

  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
  }, []);

  let stateTemplate = {};
  stateTemplate[CONST.NAME_FNAME] = "";
  stateTemplate[CONST.NAME_LNAME] = "";

  const [fullName, setFullName] = useState(
    fData[CONST.DATA][CONST.CN_NAME]
      ? fData[CONST.DATA][CONST.CN_NAME][CONST.DATA] || stateTemplate
      : stateTemplate
  );
  const [isInputValid, setIsInputValid] = useState(true);

  const canContinue = () => {
    return (
      fullName[CONST.NAME_FNAME] !== "" && fullName[CONST.NAME_LNAME] !== ""
    );
  };

  const formatInput = input => {
    let updatedInp = input;
    updatedInp = updatedInp.replace(/[^A-Za-z]/gi, "");

    if (updatedInp.length > 20)
      updatedInp = updatedInp.slice(0, updatedInp.length - 1);

    return updatedInp;
  };

  const handleChange = event => {
    const { name, value } = event.target;
    let _fullName = { ...fullName };
    _fullName[name] = formatInput(value);
    setIsInputValid(true);
    setFullName(_fullName);
  };

  const onNextClick = () => {
    if (canContinue()) {
      let componentState = {};
      componentState[CONST.DATA] = { ...fullName };

      let componentInfo = {};
      componentInfo[CONST.DATA] = componentState;
      componentInfo[CONST.NEXT_COMPONENT] = NEXT_COMPONENT;

      handleNextClick(componentInfo);
    } else {
      setIsInputValid(false);
    }
  };

  const onBackClick = () => {
    setIsInputValid(true);
    handleBackClick();
  };

  return (
    <div className="name row">
      <div className="col-12 query">
        <h2>{QUERY}</h2>
      </div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please enter first and last name
          </div>
        ) : null}

        <div className="row">
          <div className="col-12">
            <Input
              innerRef={inputEl}
              type="text"
              placeholder="First Name"
              name={CONST.NAME_FNAME}
              value={fullName[CONST.NAME_FNAME]}
              onChange={evt => handleChange(evt)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Input
              type="text"
              placeholder="Last Name"
              name={CONST.NAME_LNAME}
              value={fullName[CONST.NAME_LNAME]}
              onChange={evt => handleChange(evt)}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <NextButton onNextClick={onNextClick} />
        <BackButton onBackClick={onBackClick} />
      </div>
    </div>
  );
}
