import React, { useState, useRef, useEffect } from "react";

import { Input } from "reactstrap";
import * as CONST from "../../CONSTANTS";
import BackButton from "./backButton";
import NextButton from "./nextButton";

export default function email({ fData, handleNextClick, handleBackClick }) {
  const QUERY = "What is your email?";
  const NEXT_COMPONENT = CONST.CN_PHONE;

  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
  }, []);

  const [inputValue, setInputValue] = useState(
    fData[CONST.DATA][CONST.CN_EMAIL] || ""
  );
  const [isInputValid, setIsInputValid] = useState(true);

  const canContinue = () => {
    return CONST.REGEX_EMAIL.test(inputValue);
  };

  const handleChange = event => {
    const value = event.target.value;
    setIsInputValid(true);
    setInputValue(value);
  };

  const onNextClick = () => {
    if (canContinue()) {
      setIsInputValid(true);

      let componentInfo = {};
      componentInfo[CONST.DATA] = inputValue;
      componentInfo[CONST.NEXT_COMPONENT] = NEXT_COMPONENT;
      handleNextClick(componentInfo);
    } else {
      setIsInputValid(false);
    }
  };

  const onBackClick = () => {
    setIsInputValid(true);
    handleBackClick();
  };

  return (
    <div className="email row">
      <div className="col-12 query">
        <h2>{QUERY}</h2>
      </div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please enter a valid email address
          </div>
        ) : null}
        <div className="row">
          <div className="col-12">
            <Input
              innerRef={inputEl}
              type="text"
              placeholder="Email"
              name={CONST.CN_EMAIL}
              value={inputValue}
              onChange={evt => handleChange(evt)}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <NextButton onNextClick={onNextClick} />
        <BackButton onBackClick={onBackClick} />
      </div>
    </div>
  );
}
