import React, { useState, useRef, useEffect } from "react";

import { Input } from "reactstrap";
import * as CONST from "../../CONSTANTS";
import NextButton from "./nextButton";

export default function zipCode({ fData, handleNextClick }) {
  const QUERY = "Please enter your zip code";
  const NEXT_COMPONENT = CONST.CN_GENDER;

  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
  }, []);

  const [inputValue, setInputValue] = useState(
    fData[CONST.DATA][CONST.CN_ZIP_CODE] || ""
  );
  const [isInputValid, setIsInputValid] = useState(true);

  const canContinue = () => {
    return inputValue.length === 5;
  };

  const sanitizeInput = input => {
    let updatedInp = input;
    let lastInpChar = updatedInp.slice(updatedInp.length - 1);

    lastInpChar = lastInpChar.replace(/[^0-9]/gi, "");
    updatedInp = updatedInp
      .substring(0, updatedInp.length - 1)
      .concat(lastInpChar);

    if (updatedInp.length > 5)
      updatedInp = updatedInp.slice(0, updatedInp.length - 1);

    return updatedInp;
  };

  const handleChange = event => {
    const value = sanitizeInput(event.target.value);
    setIsInputValid(true);
    setInputValue(value);
  };

  const onNextClick = () => {
    if (canContinue()) {
      setIsInputValid(true);

      let componentInfo = {};
      componentInfo[CONST.DATA] = inputValue;
      componentInfo[CONST.NEXT_COMPONENT] = NEXT_COMPONENT;
      handleNextClick(componentInfo);
    } else {
      setIsInputValid(false);
    }
  };

  return (
    <div className="zip-code row">
      <div className="col-12 query">
        <h2>{QUERY}</h2>
      </div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please enter valid zip code
          </div>
        ) : null}

        <div className="row">
          <div className="col-12">
            <Input
              innerRef={inputEl}
              type="text"
              placeholder="Zip Code"
              name={CONST.CN_ZIP_CODE}
              value={inputValue}
              onChange={evt => handleChange(evt)}
              className="form-control col-12"
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <NextButton onNextClick={onNextClick} />
      </div>
    </div>
  );
}
