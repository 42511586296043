import React, { useState } from "react";

import * as CONST from "../../CONSTANTS";
import DobInput from "./dobInput";
import BackButton from "./backButton";
import NextButton from "./nextButton";

export default function dateOfBirth({
  fData,
  handleNextClick,
  handleBackClick,
}) {
  const QUERY = "What is your date of birth?";
  const CN_NEXT = CONST.CN_NAME;

  const [dateObject, setDateObject] = useState(
    fData[CONST.DATA][CONST.CN_DOB] !== undefined
      ? fData[CONST.DATA][CONST.CN_DOB][CONST.DATA] || CONST.DATE_STATE_STRUCT
      : CONST.DATE_STATE_STRUCT
  );
  const [isValidated, setIsValidated] = useState(true);
  const [daysValid, setDaysValid] = useState(true);

  const isValidInputValue = () => {
    //check inputs are filled
    if (
      dateObject[CONST.MONTH][CONST.SELECT_VALUE] !== 0 &&
      dateObject[CONST.DAY][CONST.SELECT_VALUE] !== 0 &&
      dateObject[CONST.YEAR][CONST.SELECT_VALUE] !== 0
    ) {
      if (
        dateObject[CONST.DAY][CONST.SELECT_VALUE] <=
        CONST.DAYS_IN_MONTH[dateObject[CONST.MONTH][CONST.SELECT_VALUE] - 1]
      ) {
        setDaysValid(true);
        return true;
      } else {
        setDaysValid(false);
        return false;
      }
    } else {
      return false;
    }
  };

  const onNextClick = () => {
    if (isValidInputValue()) {
      let componentStateTemplate = {};
      let componentInfo = {};

      componentInfo[CONST.NEXT_COMPONENT] = CN_NEXT;
      componentStateTemplate[CONST.DATA] = dateObject;
      componentInfo[CONST.DATA] = componentStateTemplate;

      handleNextClick(componentInfo);
    } else {
      setIsValidated(false);
    }
  };

  const onBackClick = () => {
    setIsValidated(true);
    setDaysValid(true);
    handleBackClick();
  };

  return (
    <div className="date-of-birth row">
      <div className="col-12 query">
        <h2>{QUERY}</h2>
      </div>
      <div className="col-12 inputs-wrap">
        {!isValidated ? (
          <div className="alert alert-warning p-2 mb-2">
            Please enter your date of birth
          </div>
        ) : null}
        {!daysValid ? (
          <div className="alert alert-warning p-2 mb-2">
            The selected month does not contain this day
          </div>
        ) : null}

        <DobInput dateObject={dateObject} setDateObject={setDateObject} />
      </div>
      <div className="col-12">
        <NextButton onNextClick={onNextClick} />
        <BackButton onBackClick={onBackClick} />
      </div>
    </div>
  );
}
