import React, { useState, useRef, useEffect } from "react";

import { Input } from "reactstrap";
import * as CONST from "../../CONSTANTS";
import BackButton from "./backButton";
import NextButton from "./nextButton";
import {
  getCurrentPhoneNumber,
  getCurrentPhoneNumberFormatted,
  defaultPhoneNumber,
  defaultPhoneNumberFormatted,
} from "../thirdParty/leadspedia";

export default function phone({ fData, handleNextClick, handleBackClick }) {
  const QUERY = "What is your phone number?";
  const NEXT_COMPONENT = CONST.CN_SEND_LEAD;
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
  const [phoneNumberFormatted, setPhoneNumberFormatted] = useState(
    defaultPhoneNumberFormatted
  );

  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
  }, []);

  useEffect(() => {
    setPhoneNumber(getCurrentPhoneNumber());
    setPhoneNumberFormatted(getCurrentPhoneNumberFormatted());
  }, []);

  const [inputValue, setInputValue] = useState(
    fData[CONST.DATA][CONST.CN_PHONE] || ""
  );
  const [isInputValid, setIsInputValid] = useState(true);

  const canContinue = () => {
    return CONST.REGEX_PHONE.test(inputValue);
  };

  const sanitizeInput = input => {
    let updatedInp = input;
    let lastInpChar = updatedInp.slice(updatedInp.length - 1);

    lastInpChar = lastInpChar.replace(/[^0-9]/gi, "");
    updatedInp = updatedInp
      .substring(0, updatedInp.length - 1)
      .concat(lastInpChar);

    // Changed to (555)123-4567
    //if (updatedInp.length > 14) updatedInp = updatedInp.slice(0, updatedInp.length - 1);
    if (updatedInp.length > 10)
      updatedInp = updatedInp.slice(0, updatedInp.length - 1);

    return updatedInp;
  };

  const handleChange = event => {
    const value = sanitizeInput(event.target.value);
    setIsInputValid(true);
    setInputValue(value);
  };

  const onNextClick = () => {
    if (canContinue()) {
      setIsInputValid(true);

      let componentInfo = {};
      componentInfo[CONST.DATA] = inputValue;
      componentInfo[CONST.NEXT_COMPONENT] = NEXT_COMPONENT;
      handleNextClick(componentInfo);
    } else {
      setIsInputValid(false);
    }
  };

  const onBackClick = () => {
    setIsInputValid(true);
    handleBackClick();
  };

  return (
    <div className="phone row">
      <div className="col-12 query">
        <h2>{QUERY}</h2>
      </div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please enter a valid phone
          </div>
        ) : null}
        <div className="row">
          <div className="col-12 mb-4">
            <Input
              innerRef={inputEl}
              type="text"
              placeholder="Phone"
              name={CONST.CN_PHONE}
              value={inputValue}
              onChange={evt => handleChange(evt)}
            />
          </div>

          <div className="col-12">
            <NextButton onNextClick={onNextClick} />
          </div>
        </div>
      </div>
      <div className="col-12">
        <label className="tcpa-label">
          <input
            type="hidden"
            id="leadid_tcpa_disclosure"
            name="tcpa_disclosure"
          />
          <span>
            By clicking the button and submitting this form, I agree that I am
            18+ years old and agree to the{" "}
            <a target="_blank" href="https://healthy-quotes.com/privacy-policy" rel="noreferrer">
              <strong>Privacy Policy</strong>
            </a>{" "}
            and{" "}
            <a target="_blank" href="https://healthy-quotes.com/terms-and-conditions" rel="noreferrer">
              <strong>Terms & Conditions</strong>
            </a>
            . By clicking the button and submitting this form, I provide my
            signature giving express consent to receive marketing communications
            via automated telephone dialing systems, artificial or pre-recorded
            voices, emails, live phone calls, pre-recorded calls, postal mail,
            text messages via SMS or MMS and other forms of communication
            regarding offers for Medicare Supplement, Medicare Advantage, Part
            D, Health Insurance, ACA and other senior home, health and
            assistance products from Lendmarx and/or one or more of its{" "}
            <a
              target="_blank"
              href="https://healthy-quotes.com/tcpa"
              rel="noreferrer"
            >
              <strong>marketing partners</strong>
            </a>{" "}
            and agents to the number(s) and/or email I provided, including a
            mobile phone, even if I am on a state or federal Do Not Call and/or
            Do Not Email registry. Agents are not connected with or endorsed by
            the U.S. government or the federal Medicare program. The list of
            companies participating are subject to change. I understand my
            wireless carrier may impose charges for calls or texts. I understand
            that my consent to receive communications is not a condition of
            purchase and I may revoke my consent at any time. To receive quotes
            without providing consent, please call{" "}
            <a href={`tel:${phoneNumber}`}>
              <strong>{phoneNumberFormatted}</strong>
            </a>
            .
          </span>
        </label>
      </div>
      <div className="col-12">
        <BackButton onBackClick={onBackClick} />
      </div>
    </div>
  );
}
